type HeadlineSchemaProps = {
  title?: string;
  author?: string;
  image?: string;
  locale: string;
  mainEntityOfPage: string;
  dateModified?: string | Date;
  datePublished?: string | Date;
  abstract?: string;
  description?: string;
};

export const HeadlineSchema = ({
  title,
  author,
  locale,
  image,
  mainEntityOfPage,
  dateModified,
  datePublished,
  abstract,
  description,
}: HeadlineSchemaProps) => {
  return (
    <>
      {title && <meta itemProp="headline" content={`${title}`} />}
      {abstract && <meta itemProp="abstract" content={`${abstract}`} />}
      {description && (
        <meta itemProp="description" content={`${description}`} />
      )}
      {author && (
        <div itemProp="author" itemScope itemType="https://schema.org/Person">
          <meta itemProp="name" content={`${author}`} />
          <meta
            itemProp="url"
            content={`https://cdn.shopify.com/s/files/1/0840/8370/3830/files/159308894-${author}.png`}
          />
        </div>
      )}
      {datePublished && (
        <meta itemProp="datePublished" content={`${datePublished}`} />
      )}
      {dateModified && (
        <meta itemProp="dateModified" content={`${dateModified}`} />
      )}

      {image && <meta itemProp="image" content={`${image}`} />}
      <meta itemProp="inLanguage" content={`${locale}`} />
      <meta itemProp="mainEntityOfPage" content={`${mainEntityOfPage}`} />
    </>
  );
};
